import React from 'react';
import classnames from 'classnames';
export const Tooltip = ({
  content,
  children,
  className,
  arrowClassName,
  direction = 'bottom',
}: {
  content: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  arrowClassName?: string;
  direction?: 'bottom' | 'top' | 'left' | 'right';
}) => {
  const getDirectionClass = () => {
    if (direction === 'bottom') {
      return '-bottom-[32px] left-2';
    }
    if (direction === 'top') {
      return '-top-[32px]';
    }
    if (direction === 'left') {
      return '-left-4 -translate-x-full';
    }
    return '-right-4 translate-x-full';
  };
  const getArrowDirectionClass = () => {
    if (direction === 'bottom') {
      return `before:top-0 before:-translate-y-full before:left-1/2 before:-translate-x-1/2 before:border-b-gray-90`;
    }
    if (direction === 'top') {
      return `before:bottom-0 before:translate-y-full before:left-1/2 before:-translate-x-1/2 before:border-t-gray-90`;
    }
    if (direction === 'left') {
      return `before:right-0 before:translate-x-full before:top-1/2 before:-translate-y-1/2 before:border-l-gray-90`;
    }
    return `before:-left-0 before:-translate-x-full before:top-1/2 before:-translate-y-1/2 before:border-r-gray-90`;
  };

  const directionClass = getDirectionClass();
  const arrowDirectionClass = getArrowDirectionClass();
  return (
    <div className={classnames('tooltip-container relative', className)}>
      {children}
      <div
        className={classnames(
          'tooltip-static absolute text-label-2-b px-1.5 py-1 rounded-md',
          `bg-gray-90 text-white`,
          directionClass,
          'before:content-[""] before:absolute before:w-0 before:h-0 before:border-[6px] before:border-transparent',
          arrowDirectionClass,
          arrowClassName
        )}
      >
        {content}
      </div>
    </div>
  );
};
