import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

export const Sheet = ({
  open,
  setOpen,
  children,
  onOpen,
  onClose,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
}) => {
  const backdropRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      requestAnimationFrame(() => {
        if (backdropRef.current && containerRef.current) {
          backdropRef.current.style.opacity = '0.5';
          containerRef.current.style.transition = 'transform 0.5s';
          containerRef.current.style.transform = 'translateX(0)';
          onOpen?.();
        }
      });
    } else {
      document.body.style.overflow = 'unset';
      requestAnimationFrame(() => {
        if (backdropRef.current && containerRef.current) {
          backdropRef.current.style.opacity = '0';
          containerRef.current.style.transition = 'transform 0.15s';
          containerRef.current.style.transform = 'translateX(100%)';
          onClose?.();
        }
      });
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <>
      <div
        ref={backdropRef}
        className={classNames(
          'caregiver-sheet-backdrop fixed inset-0 bg-black opacity-0 z-50',
          open ? 'flex' : 'hidden'
        )}
        style={{
          transition: 'opacity 0.15s',
        }}
        onClick={() => setOpen(false)}
      ></div>
      <div
        ref={containerRef}
        className={classNames(
          'caregiver-sheet-container fixed top-0 bottom-0',
          'right-0 flex justify-center items-center z-50 translate-x-full',
          'w-[50%] max-lg:w-full max-w-[700px] h-full'
        )}
        style={{
          transition: 'transform 0.5s',
          animationTimingFunction: 'cubic-bezier(.4,0,.2,1)',
          transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
        }}
      >
        {children}
      </div>
    </>
  );
};
